import PropTypes from "prop-types"
import React from "react"


import Styles from "./IntroText.module.css"

const IntroText = ({ title, description }) => (
    <div className={Styles.wrapper}>
        <h2>{title}</h2>
        <p>{description}</p>
    </div>
)

IntroText.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
}

export default IntroText

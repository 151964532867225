import PropTypes from "prop-types"
import React from "react"

import Styles from "./SmallText.module.css"

const SmallText = ({ title, description }) => (
    <div>
        <h4 className={Styles.h4}>{title}</h4>
        <p>{description}</p>
    </div>
)

SmallText.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
}

export default SmallText
import PropTypes from "prop-types"
import React from "react"

import Styles from "./BgImageTextbox.module.css"

const BgImageTextbox = ({ Background, boxcolor, children }) => (
    <div className={Styles.background} style={{ backgroundImage: "url(" + Background + ")" }}>
        <div className={Styles.section}>
            <div className={`${boxcolor}`}>
                <div className={Styles.box}>
                    {children}
                </div>
            </div>
        </div>
    </div>
)

BgImageTextbox.propTypes = {
    Background: PropTypes.string,
}

export default BgImageTextbox
import PropTypes from "prop-types"
import React from 'react'
import { Slide } from 'react-slideshow-image'
import Image from 'gatsby-image'

import Styles from './Slider.module.css'
import SmallText from '../SmallText/SmallText'


const Slideshow = ({ items }) => {
    const properties = {
        duration: 100,
        transitionDuration: 750,
        infinite: true,
        indicators: true,
        arrows: true,
        autoplay: false,
        onChange: (oldIndex, newIndex) => {
            console.log(`slide transition from ${oldIndex} to ${newIndex}`);
        }
    }

    var i;
    var doubleitems = [];
    for (i = 0; i < items.length; i++) {
        var twoitems = [];
        twoitems.push(items[i]);
        i++;
        twoitems.push(items[i]);
        doubleitems.push(twoitems);
    }

    return (
        <>
            <div className={Styles.single}>
                <Slide {...properties}>
                    {items.map(item => (
                        <div key={`images-${item.id}`} className={Styles.eachslide}>
                            <div className={Styles.imagewrapper}>
                                <div className={Styles.imagebox}>
                                    <Image
                                        fluid={item.image}
                                        alt={item.alt}
                                        className={Styles.image}
                                        loading="lazy"
                                    />
                                    <span><SmallText title={item.title} description={item.description}></SmallText></span>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slide>
            </div>
            <div className={Styles.double}>
                <Slide {...properties}>
                    {doubleitems.map(item => (
                        <div key={`double-images-${item[0].id}`} className={Styles.eachslide}>
                            <div className={`${Styles.imagewrapper} ${Styles.firstwrapper}`}>
                                <div className={Styles.imagebox}>
                                    <Image
                                        fluid={item[0].image}
                                        alt={item.alt}
                                        className={Styles.image}
                                        loading="lazy"
                                    />
                                    <span><SmallText title={item[0].title} description={item[0].description}></SmallText></span>
                                </div>
                            </div>
                            <div className={Styles.imagewrapper}>
                                <div className={Styles.imagebox}>
                                    <Image
                                        fluid={item[1].image}
                                        alt={item.alt}
                                        className={Styles.image}
                                        loading="lazy"
                                    />
                                    <span><SmallText title={item[1].title} description={item[1].description}></SmallText></span>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slide>
            </div>
        </>
    )
}

Slideshow.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      alt: PropTypes.string,
      title: PropTypes.string.isRequired,
      description: PropTypes.node.isRequired,
    })
  ).isRequired,
}

export default Slideshow
import React from "react"
import TimelineStyles from "./Timeline.module.css"

export default props =>
<div className={TimelineStyles.timeline}>
    <div className={TimelineStyles.timestamp}>
        <h3>{props.TimeStamp}</h3>
    </div>
    <div className={TimelineStyles.line}>
        <div className={TimelineStyles.timecircle}></div>
    </div>
    <div className={TimelineStyles.textcontainer}>
        <div className={TimelineStyles.infotext}>
            <h4>{props.TimeTitle}</h4>
            <p>{props.TimeText}</p>
        </div>
    </div>
</div>
import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Timeline from "../components/timeline/Timeline"
import Styles from "./index.module.css"

import Section from "../components/Section/Section"
import IconSection from "../components/IconSection/IconSection"
import BgImageTextbox from "../components/BgImageTextbox/BgImageTextbox"
import IntroText from "../components/IntroText/IntroText"
import SmallText from "../components/SmallText/SmallText"
import Slider from "../components/Slider/Slider"
import Quote from "../components/Quote/Quote"

import Bau from "../images/kneopke_header_02.jpg"
import QuoteBg from "../images/bg_zitate.jpg"

import SanierungIcon from "../images/sanierung.png"
import AbdichtungIcon from "../images/abdichtung.png"
import AbbrucharbeitenIcon from "../images/abbrucharbeiten.png"
import BetonarbeitenIcon from "../images/betonarbeiten.png"
import MaurerarbeitenIcon from "../images/mauerarbeiten.png"
import SchalungsarbeitenIcon from "../images/schalungsarbeiten.png"
import StahltraegerIcon from "../images/stahltraeger.png"
import SpengeIcon from "../images/spenge.png"
import KnoepkeIcon from "../images/knoepke.png"


const IndexPage = () => {
  const { Betongiessen, Steinmauern, Halleinnen, Hallenbau, Unterfangung, Abdichtung } = useStaticQuery(graphql`
            fragment Slider on File {
            childImageSharp {
                fluid(maxWidth: 600, maxHeight: 400, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        query SliderQuery {
            Betongiessen: file(
                relativePath: { eq: "beton_giessen.jpg" }
            ) {
                ...Slider
            }
            Steinmauern: file(
                relativePath: { eq: "steinmauern.jpg" }
            ) {
                ...Slider
            }
            Halleinnen: file(
                relativePath: { eq: "halle_innen.jpg" }
            ) {
                ...Slider
            }
            Hallenbau: file(
                relativePath: { eq: "industriebau.jpg" }
            ) {
                ...Slider
            }
            Unterfangung: file(
                relativePath: { eq: "stahltraeger.jpg" }
            ) {
                ...Slider
            }
            Abdichtung: file(
                relativePath: { eq: "abdichtungen.jpg" }
            ) {
                ...Slider
            }
        }
        `)

return (
  <Layout>
    <SEO title="Home" />
    <div id="start">
      <BgImageTextbox Background={Bau} boxcolor="bg-grey">
        <h1 className="mb-0">KNÖPKE Bauunternehmen GmbH</h1>
        <h2 className="font-light mb-0">Termingetreu. Kompetent. Strukturiert.</h2>
        <p className="font-thin mt-3 sm:mt-6">Anfragen gerne über Telefon: <a href="tel:+495225859771"><b className={Styles.nobreak}>0 52 25 &ndash; 85 97 71</b></a> oder mittels E-Mail: <a href="mailto:info@knoepke-bau.de"><b className={Styles.nobreak}>info@knoepke-bau.de</b></a></p>
      </BgImageTextbox>
    </div>
    <div id="leistungen">
      <Section>
        <IntroText title="Wir sind Ihr zuverlässiger Partner bei der Umsetzung von komplexen Bauvorhaben in OWL"
          description="Von kleinen Umbauten, großen Abfangungen von Gebäuden bis hin zu Fundamenten für große Industriehallen oder Rohbauten für Einfamilienhäuser, Mehrfamilienhäuser und Geschäftshäusern &ndash; wir setzen Ihr individuelles Vorhaben zur vollen Zufriedenheit um. Ob Privatperson, öffentl. Auftraggeber, Architekt oder Unternehmen &ndash; zählen Sie auf unsere mehr als 40-jährige Erfahrung als Spezialist im Bereich Betonarbeiten sowie Betonschalungsarbeiten."
        />
        <h3>Unsere Schwerpunkte und Kompetenzen:</h3>
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2">
            <IconSection icon={SchalungsarbeitenIcon}>
              <SmallText title="Schalungsarbeiten" description="Für Fundamente, Bodenplatten, Treppen, Unterzüge / Balken, Schwimmbäder usw. &ndash; wir können jede Form einschalen." />
            </IconSection>
            <IconSection icon={BetonarbeitenIcon}>
              <SmallText title="Betonarbeiten" description="Für genannte Bauteile der Schalungsarbeit bauen wir die erforderliche Betonstahlbewehrung und den Beton ein." />
            </IconSection>
            <IconSection icon={MaurerarbeitenIcon}>
              <SmallText title="Maurerarbeiten" description="Für Rohbauten bei Wohnhäusern, Geschäftshäusern oder Schulen, sowie kleine und große Um- und Anbauten im oder am Bauobjekt." />
            </IconSection>
            <IconSection icon={AbdichtungIcon}>
              <SmallText title="Abdichtungen" description="Wir beheben nachträgliche Feuchtigkeitsschäden, etwa bei Kellerwänden, und dichten bei Neubauten entsprechend der DIN ab." />
            </IconSection>
          </div>
          <div className="w-full md:w-1/2 mt-5 md:mt-0">
            <IconSection icon={StahltraegerIcon}>
              <SmallText title="Abfangungen mit Stahlträgern" description="Ausbau der Wand, Einbau des neuen Trägers. Etwa bei großen Gebäuden, Fundamenten oder Industriehallen oder bei kleinen Umbauten wie etwa neue Tür- oder Fensteröffnungen." />
            </IconSection>
            <IconSection icon={SanierungIcon}>
              <SmallText title="Sanierungen" description="Wir nehmen Sanierungen an Altbauten, Industrieböden und anderen Bauobjekten vor." />
            </IconSection>
            <IconSection icon={AbbrucharbeitenIcon}>
              <SmallText title="Abbrucharbeiten" description="Auf Wunsch leisten wir kleine oder große Abbrucharbeiten." />
            </IconSection>
          </div>
        </div>
      </Section>
      <section className="bg-light">
        <div className={Styles.sectionHeight}>
          <div className={Styles.sectionWidth}>
            <h2>Referenzen von Kundenprojekten</h2>
          </div>
          <div className={Styles.sectionSmall}>
            <Slider
              items={[
                {
                  id: "beton",
                  image: Betongiessen.childImageSharp.fluid,
                  title: "Betonarbeiten",
                  description:
                    "Fundamentherstellung für eine Asphaltmischanlage.",
                },
                {
                  id: "halle",
                  image: Halleinnen.childImageSharp.fluid,
                  title: "Hallenbau",
                  description:
                    <>Umsetzung einer Industriehalle mit einer Größe von 6000 m&sup2;.</>,
                },
                {
                  id: "stein",
                  image: Steinmauern.childImageSharp.fluid,
                  title: "Schlüsselfertiges Bauen / Pavillon",
                  description:
                    "Realisierung eines kompletten Bauprojektes mit individuellen Maurerarbeiten, teilweise aus Naturstein. Hingucker: moderne Trennwand aus dunklen Steinen im Essbereich.",
                },
                {
                  id: "industrie2",
                  image: Hallenbau.childImageSharp.fluid,
                  title: "Industriebau",
                  description:
                    "Realisierung eines Hallenbau-Projektes unter anderem mit 8 Überladerampen und einer Sprinklerzentrale.",
                },
                {
                  id: "unterfangung",
                  image: Unterfangung.childImageSharp.fluid,
                  title: "Unterfangung mit Stahlträger",
                  description:
                    "Raumerweiterung durch Entfernen der Wände und Erhalt der Statik mit einer auf den Kundenwunsch angepasste Trägerkonstruktion.",
                },
                {
                  id: "abdichtung",
                  image: Abdichtung.childImageSharp.fluid,
                  title: "Gebäudeabdichtung",
                  description:
                    "Nachträgliche Abdichtung als Sanierung eines Sockelbereichs aufgrund von Feuchtigkeitsschäden.",
                },
              ]}
            />
          </div>
        </div>
      </section>
    </div>
    <div id="historie">
      <Section>
        <h2>Unternehmensgeschichte - Von damals zu heute</h2>
        <Timeline
          TimeStamp="1980"
          TimeTitle="Gründung von F&amp;K Schalungsbetrieb GmbH"
          TimeText="Am 01.08.1980 gründet Eckhard Knöpke zusammen mit einem Partner das Unternehmen, F&amp;K Schalungsbetrieb GmbH."
        />
        <Timeline
          TimeStamp="1988"
          TimeTitle="Holger Knöpke tritt in das Unternehmen ein"
          TimeText="Nach seiner Ausbildung zum Beton- und Stahlbetonbauer bei der Fa. DYWIDAG tritt Holger Knöpke in das Unternehmen ein."
        />
        <Timeline
          TimeStamp="1990/ 1991"
          TimeTitle="Ausdehnung des Aufgabenbereiches"
          TimeText="Im September 1991 erlang Holger Knöpke mit nur 23 Jahren seine Meisterprüfung als Beton- und Stahlbetonbauer. F&amp;K Schalungsbetrieb ist in der Lage sein Leistungsspektrum zu erweitern. Seit 2005 ist Holger Knöpke zudem staatlich geprüfter Hochbautechniker."
        />
        <Timeline
          TimeStamp="1995"
          TimeTitle="F&amp;K Schalungsbetrieb wird zum Ausbilder"
          TimeText="Seit 1995 bildet das Unternehmen Beton- und Stahlbetonbauer aus. Bisher haben 10 Auszubildende ihre Ausbildung begonnen. Zwei ehemalige Auszubildende arbeiten seitdem erfolgreich als Gesellen im Schalungsbetrieb. Aktuell befinden sich zwei Auszubildende im 3. Lehrjahr auf dem Weg zur Gesellenprüfung."
        />
        <Timeline
          TimeStamp="2005"
          TimeTitle="Das Unternehmen vergrößert sich"
          TimeText="Man entscheidet sich das Bauunternehmen Fa. Steinmeier Haus GmbH, aus Bruchmühlen, zu übernehmen und führt es vorerst unter selbigen Namen weiter. Mit der Übernahme wird der Kundenstamm weiter ausgebaut und die Kompetenz auf den Bau zahlreicher Industriehallen, bis zu Größen von 6000 m&sup2; ausgedehnt. Kundenaufträge von kompletten Rohbauten, Umbauten und Sanierungsarbeiten zählen nun zum erweiterten Leistungsangebot."
        />
        <Timeline
          TimeStamp="2010"
          TimeTitle="Holger Knöpke wird Geschäftsführer"
          TimeText="Seit 2010 nun schon leitet Holger Knöpke das Bauunternehmen F&amp;K Schalungsbetrieb GmbH."
        />
        <Timeline
          TimeStamp="2012"
          TimeTitle="Knöpke Bauunternehmen wird geboren"
          TimeText="Ende 2012 verschmelzen die beiden Firmen &ndash; F&amp;K Schalungsbetrieb und Bauunternehmen Fa. Steinmeier Haus GmbH &ndash; zur Knöpke Bauunternehmen GmbH."
        />
        <Timeline
          TimeStamp="Heute"
          TimeTitle="Ihr kompetenter Partner in OWL"
          TimeText="Mittlerweile werden komplette Rohbauten vom Einfamilienhaus bis zum Mehrfamilienhaus, Geschäftshäuser sowie Betonarbeiten und Betonschalungsarbeiten für Privatkunden und andere Bauunternehmen in ganz OWL realisiert. Für die Schalungsarbeiten wird mit eigenem Schalungsmaterial für z.B. Fundamente, Treppen, Wände, Decken oder Schwimmbäder gearbeitet."
        />
      </Section>
    </div>
    <BgImageTextbox Background={QuoteBg} boxcolor="bg-brown">
      <Quote quote="Als wir unser Haus umgebaut haben, war Herr Knöpke mit seinem Team bei uns und hat Wände entfernt, Türen zugemauert und an anderer Stelle bestehende Mauern für neue Türen geöffnet. Alle Arbeiten wurden termingerecht und mit größter Sorgfalt ausgeführt. Der von uns erwartete Schmutz und Staub blieb aus und die Baustelle wurde blitzsauber verlassen."
      name="Hans Heidemann, Spenge"/>
    </BgImageTextbox>
    <div id="kontakt">
      <Section>
        <h2>Kontakt</h2>
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2">
            <IconSection icon={SpengeIcon}>
              <h4>Anschrift</h4>
              <ul>
                <li>KNÖPKE Bauunternehmen</li>
                <li>Industriezentrum 80</li>
                <li>D-32139 Spenge</li>
              </ul>
            </IconSection>
          </div>
          <div className="w-full md:w-1/2 mt-5 md:mt-0">
            <IconSection icon={KnoepkeIcon}>
              <h4>Kontakt</h4>
              <ul>
                <li>Tel.: <a href="tel:+495225859771" class={Styles.nobreak}>0 52 25 &ndash; 85 97 71</a></li>
                <li>Fax: <span className={Styles.nobreak}>0 52 25 &ndash; 85 97 73</span></li>
                <li>E-Mail: <a href="mailto:info@knoepke-bau.de" class={Styles.nobreak}>info@knoepke-bau.de</a></li>
                <li class="pt-5">Ihr Ansprechpartner:</li>
                <li>Holger Knöpke</li>
              </ul>
            </IconSection>
          </div>
        </div>
      </Section>
    </div>
  </Layout>
)
}

export default IndexPage

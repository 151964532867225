import PropTypes from "prop-types"
import React from "react"

import Styles from "./IconSection.module.css"

const IconSection = ({ icon, children }) => {
    return (
        <div className={Styles.texticonwrapper}>
            <div className={Styles.iconwrapper}>
                <div className={Styles.iconbg}>
                    <img src={icon} alt="" />
                </div>
            </div>
            <div className={Styles.text}>
                {children}
            </div>
        </div>
    )
}

IconSection.propTypes = {
    icon: PropTypes.string.isRequired,
}

export default IconSection

import PropTypes from "prop-types"
import React from "react"

import Styles from "./Quote.module.css"

import Sign from "../../images/zitat.png"

const Quote = ({ quote, name }) => (
  <div className="flex">
    <img className={Styles.icon} src={Sign} alt=""/>
    <span className={Styles.text}>
      <p className={Styles.quote}>{quote}</p>
      <p className={Styles.name}>{name}</p>
    </span>
  </div>
)

Quote.propTypes = {
  quote: PropTypes.string,
  name: PropTypes.string,
}

export default Quote